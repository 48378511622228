import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VexModule } from '../@vex/vex.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CustomLayoutModule } from './custom-layout/custom-layout.module';
import { NotifierComponent } from './pages/shared/notifier/notifier.component';
import { LoaderClientModuleModule } from './pages/loader-client/loader-client.module';
import { BitacoraInterceptor } from './interceptors/bitacora.interceptor';
import localeES from '@angular/common/locales/es';
import {registerLocaleData} from '@angular/common';
registerLocaleData(localeES, 'es');

@NgModule({
  declarations: [AppComponent, NotifierComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LoaderClientModuleModule,
    // Vex
    VexModule,
    CustomLayoutModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es' },
    { provide: HTTP_INTERCEPTORS, useClass: BitacoraInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
