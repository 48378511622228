import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import { VexRoutes } from '../@vex/interfaces/vex-route.interface';

const routes: VexRoutes = [
   {
     path: 'login',
     loadChildren: () => import('./pages/auth/login/login.module').then(m => m.LoginModule),
   },
  {
    path: 'register',
    loadChildren: () => import('./pages/auth/register/register.module').then(m => m.RegisterModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
  },
  {
    path: 'change-password',
    loadChildren: () => import('./pages/auth/change-password/change-password.module').then(m => m.ChangePasswordModule),
  },
  {
    path: 'follow-up',
    loadChildren: () => import('./pages/admin/follow-up/follow-up.module').then(m => m.FollowUpModule),
  },
  // {
  //   path: 'coming-soon',
  //   loadChildren: () => import('./pages/pages/coming-soon/coming-soon.module').then(m => m.ComingSoonModule),
  // },
  {
    path: '',
    component: CustomLayoutComponent,
    children: [
      {
        path: 'dashboards/analytics',
        redirectTo: '/'
      },
      {
        path: '',
        loadChildren: () => import('./pages/dashboards/dashboard-analytics/dashboard-analytics.module').then(m => m.DashboardAnalyticsModule),
      },
      {
        path: 'normativa',
        children: [
          {
            path: ':tipoNormativa',
            loadChildren: () => import('./pages/admin/normativas/normativas.module').then(m => m.NormativasModule)
          },
        ]
      },
      {
        path: 'modulo-tree',
        children: [
          {
            path: ':frontType',
            loadChildren: () => import('./pages/admin/modulo-tree/modulo-tree.module').then(m => m.ModuloTreeModule)
          },
        ]
      },
      {
        path: 'admin',
        children: [
          {
            path: 'estorg/:contract',
            loadChildren: () => import('./pages/admin/entidades/form-wizard/form-wizard.module').then(m => m.FormWizardModule)
          },
          {
            path: 'eo/:contract',
            loadChildren: () => import('./pages/admin/entidades/form-wizard/form-wizard.module').then(m => m.FormWizardModule)
          },
          {
            path: 'modulo-tree',
            children: [
              {
                path: ':frontType',
                loadChildren: () => import('./pages/admin/modulo-tree/modulo-tree.module').then(m => m.ModuloTreeModule)
              },
            ]
          },
          // {
          //   path: 'modulo-tree',
          //   children: [
          //     {
          //       path: ':frontType',
          //       loadChildren: () => import('./pages/admin/modulo-tree/modulo-tree.module').then(m => m.ModuloTreeModule)
          //     }
          //   ]
          // },
          {
            path: 'roles',
            loadChildren: () => import('./pages/admin/rol-modulo/rol-modulo.module').then(m => m.RolModuloModule)
          },
          {
            path: 'users/:contract',
            loadChildren: () => import('./pages/admin/entidades/users/users.module').then(m => m.UsersModule)
          },
          {
            path: 'list/:contract',
            loadChildren: () => import('./pages/admin/entidades/users/users.module').then(m => m.UsersModule)
          },
          {
            path: 'usersocs',
            loadChildren: () => import('./pages/admin/users-soc/users-soc.module').then(m => m.UsersSocModule)
          },
          {
            path: 'rol',
            loadChildren: () => import('./pages/admin/roles/roles.module').then(m => m.RolesModule)
          },
          {
            path: 'bitacora',
            loadChildren: () => import('./pages/admin/bitacora/bitacora.module').then(m => m.BitacoraModule)
          },
        ],
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
