import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MenuItem } from '../interfaces/menu-item.interface';
import { trackById } from '../../../../utils/track-by';
import icPerson from '@iconify/icons-ic/twotone-person';
import icSettings from '@iconify/icons-ic/twotone-settings';
import icAccountCircle from '@iconify/icons-ic/twotone-account-circle';
import icChevronRight from '@iconify/icons-ic/twotone-chevron-right';
import icLock from '@iconify/icons-ic/twotone-lock';
import { Icon } from '@visurel/iconify-angular';
import { PopoverRef } from '../../../../components/popover/popover-ref';

import { AuthService } from '../../../../../app/auth.service';

@Component({
  selector: 'vex-toolbar-user-dropdown',
  templateUrl: './toolbar-user-dropdown.component.html',
  styleUrls: ['./toolbar-user-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers:[AuthService]
})
export class ToolbarUserDropdownComponent implements OnInit {

  items: MenuItem[] = [
    {
      id: '1',
      icon: icAccountCircle,
      label: 'Mi Perfil',
      description: 'Información Personal',
      colorClass: 'text-teal',
      route: '/'
    }
  ];

  trackById = trackById;
  icPerson = icPerson;
  icSettings = icSettings;
  icChevronRight = icChevronRight;
  icLock = icLock;
  currentUserName="";
  constructor(private authService: AuthService,private cd: ChangeDetectorRef,
              private popoverRef: PopoverRef<ToolbarUserDropdownComponent>,
              ) { }

  ngOnInit() {

    var a=this.authService.getAuthenticatedUser();
    // console.info(a);
    var that=this;
    this.authService.getAuthenticatedUser().getSession((err,session)=>{
          if(err){
            return;
          }else{
            // debugger;
            // console.info(session.getIdToken().payload['cognito:username']);
            // console.info("Decode:",session.getIdToken().decodePayload());
            that.currentUserName=session.getIdToken().decodePayload().name;
          }
    });
  }
  
  close() {
    this.popoverRef.close();
  }
  logout(){
    this.authService.logout();
    localStorage.clear();
    this.close();
    // eval("window.location.replace('url');");
  }
}
