import { LoadingBarModule } from '@ngx-loading-bar/core';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from './interceptor.service';

@NgModule({
  imports: [LoadingBarModule],
  exports: [LoadingBarModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
  ],
})
export class LoaderClientModuleModule { }
