import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { User } from 'src/app/user.model';
import { cognitoConfigUsers } from 'src/environments/environment';
import { UserC, SignupData } from 'src/app/types';
import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserSession
} from 'amazon-cognito-identity-js'
import { CognitoIdentityCredentials, config as AWSConfig, AWSError } from 'aws-sdk';
import { error } from '@angular/compiler/src/util';
 
const POOL_DATA = {
  UserPoolId: cognitoConfigUsers.userPool.UserPoolId,
  ClientId: cognitoConfigUsers.userPool.ClientId
}

const UserPool = new CognitoUserPool(POOL_DATA);
export * from 'src/app/types';
@Injectable()
export class AuthUsersService {
  private static userPoolLoginKey = `cognito-idp.${cognitoConfigUsers.userPool.region}.amazonaws.com/${cognitoConfigUsers.userPool.UserPoolId}`;
  authIsLoading = new BehaviorSubject<boolean>(false);
  authDidFail = new BehaviorSubject<boolean>(false);
  authStatusChanged = new Subject<boolean>();
  registeredUser: CognitoUser;
  cognitoAwsCredentials: CognitoIdentityCredentials;
  constructor(private router: Router) { }
  async signUp(username: string, email: string, password: string): Promise<Error> {

    return new Promise((resolve, reject) => {
      this.authIsLoading.next(true);
      const user: User = {
        username: username,
        email: email,
        password: password
      };
      const attrList: CognitoUserAttribute[] = [];
      const emailAttribute = {
        Name: 'email',
        Value: user.email
      };
      attrList.push(new CognitoUserAttribute(emailAttribute));
      const nameAttribute = {
        Name: 'name',
        Value: user.username
      };
      attrList.push(new CognitoUserAttribute(nameAttribute));

      UserPool.signUp(user.email.split('@')[0] + "_" + user.email.split('@')[1], user.password, attrList, null, (err, result) => {
        if (err) {
          this.authDidFail.next(true);
          this.authIsLoading.next(false);
          resolve(err);
        } else {
          this.registeredUser = result.user;
        }
        this.authDidFail.next(false);
        this.authIsLoading.next(false);
        resolve(null);
      });

    });

  }
  confirmUser(username: string, code: string) {
    this.authIsLoading.next(true);
    const userData = {
      Username: username,
      Pool: UserPool
    };
    const cognitUser = new CognitoUser(userData);
    cognitUser.confirmRegistration(code, true, (error, result) => {
      if (error) {
        this.authDidFail.next(true);
        this.authIsLoading.next(false);
        return;
      }
      this.authDidFail.next(false);
      this.authIsLoading.next(false);
      this.router.navigate(['/']);
    });
  }
  async signIn(username: string, password: string): Promise<Object> {

    return new Promise((resolve, reject) => {

      this.authIsLoading.next(true);
      const authData = {
        Username: username,
        Password: password
      };
      const authDetails = new AuthenticationDetails(authData);
      const userData = {
        Username: username,
        Pool: UserPool
      }
      const cognitoUser = new CognitoUser(userData);
      const that = this;
      cognitoUser.authenticateUser(authDetails, {
        onSuccess: function (result: CognitoUserSession) {
          /* this.setSignInUserSession(result);  */
          that.authStatusChanged.next(true);
          that.authDidFail.next(false);
          that.authIsLoading.next(false);
          
          console.log(result);
          resolve(null);
        },
        onFailure: function (error) {
          that.authDidFail.next(true);
          that.authIsLoading.next(false);
          console.log(error);
          resolve(error);
        }

      });

      //this.authStatusChanged.next(true);
      //return;
    });

  }

  getAuthenticatedUser() {
    return UserPool.getCurrentUser();
  }
  logout() {
    this.getAuthenticatedUser().signOut();
    this.authStatusChanged.next(false);
  }
  isAuthenticated(): Observable<boolean> {
    const user = this.getAuthenticatedUser();
    const obs = Observable.create((observer) => {
      if (!user) {
        observer.next(false);
      } else {

        user.getSession((error, session) => {
          if (error) {
            observer.next(false);
          } else {
            if (session.isValid()) {
              observer.next(true);
            } else {
              observer.next(false);
            }
          }
        });
      }
      observer.complete();
    });
    return obs;
  }
  initAuth() {
    this.isAuthenticated().subscribe(
      (auth) => this.authStatusChanged.next(auth)
    );
  }
  forgotPassword(username: string) {
    return new Promise((resolve, reject) => {
      this.authIsLoading.next(true);
      const userData = {
        Username: username,
        Pool: UserPool
      };
      const cognitUser = new CognitoUser(userData);
      console.info(cognitUser);
      cognitUser.forgotPassword({

        onSuccess: function (data: any) { 
          console.info("success:");
          console.info(data);
        },
        onFailure: function (err: Error) {
          console.info("error:");
          console.info(err);
        }

      });

    });
  }

  changePassword(username: string,verificationCode:string,newPassword:string) {
    return new Promise((resolve, reject) => {

      this.authIsLoading.next(true);
      const userData = {
        Username: username,
        Pool: UserPool
      };
      const cognitUser = new CognitoUser(userData);
      console.info(cognitUser);
      
      cognitUser.confirmPassword(verificationCode,newPassword,{
        
        onSuccess: function () { 
          console.info("success:");
          resolve(null);
        },
        onFailure: function (err: Error) {
          console.info("error:");
          console.info(err);
          resolve(err);
        }

      });
      
    });
  }
//   private getCurrentCognitoUser(callback: (err1?: Error, cognitoUser?: CognitoUser) => void) {
//     const cognitoUser = UserPool.getCurrentUser();
//     if (cognitoUser) {
//         cognitoUser.getSession((err: Error, session: CognitoUserSession) => {
//             if (session && session.isValid()) {
//                 if (!this.cognitoAwsCredentials || this.cognitoAwsCredentials.needsRefresh()) {
//                     this.updateAWSCredentials(session.getIdToken().getJwtToken(), cognitoUser.getUsername(), (err2) => {
//                         if (err2) {
//                             callback(err2);
//                         } else {
//                             callback(undefined, cognitoUser);
//                         }
//                     });
//                 } else {
//                     callback(undefined, cognitoUser);
//                 }
//             } else {
//                 callback(undefined, undefined);
//             }
//         });
//     } else {
//         callback(undefined, undefined);
//     }
// }
//   getCurrentUser(callback: (err?: Error, user?: UserC) => void) {
//     this.getCurrentCognitoUser((err, cognitoUser) => {
//         if (cognitoUser && cognitoUser.getUsername()) {
//             const identityId = this.cognitoAwsCredentials ? this.cognitoAwsCredentials.identityId : undefined;
//             callback(undefined, new UserC(true, cognitoUser.getUsername(), identityId));
//         } else {
//             callback(undefined, UserC.default);
//         }
//     });
// }
// private updateAWSCredentials(sessionToken: string, username: string, callback: (err?: Error) => void) {
//   const logins = {};
//   logins[AuthService.userPoolLoginKey] = sessionToken;
//   this.cognitoAwsCredentials = new CognitoIdentityCredentials(
//       {
//           IdentityPoolId: cognitoConfigUsers.identityPoolId,
//           Logins: logins,
//           LoginId: username
//       },
//       {
//           region: cognitoConfigUsers.userPool.region
//       }
//   );
//   // call refresh method in order to authenticate user and get new temp credentials
//   this.cognitoAwsCredentials.refresh((err: AWSError) => {
//       if (err) {
//           callback(err);
//       } else {
//           AWSConfig.credentials = this.cognitoAwsCredentials;
//           callback(null);
//       }
//   });
// }

}

