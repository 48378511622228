import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
// import { AuthService } from 'src/app/auth.service';
const ENTITIE = 1;
@Injectable({
  providedIn: 'root'
})
export class EventoService {
  showLoader = false;

  constructor(
    private http: HttpClient,
    // private authService: AuthService,
    ) {  }
  show() {
    this.showLoader = true;
  }

  hide() {
    this.showLoader = false;
  }
  
  getEventos() {
    const body = {  
      action: 'find', 
      payload: {
        where : {
          idSociedad    : ENTITIE
        },
        order : {
          fechaCreacion : "DESC"
        }
      }
    };
    return this.http.post(environment.eventoURL,body);
  }

  getEvento(id: number) {
    const body = {action: 'find', payload: {where:{idEvento:id}}};
    return this.http.post(environment.eventoURL,body);
  }

  saveEvento() {
    const currentUser   = localStorage.getItem('lastAuthUser');
    const currentEmail  = localStorage.getItem('lastEmailUser');
    const description   = localStorage.getItem('description');
    const body =  { action: 'save', 
                    payload: {
                      idSociedad: ENTITIE,
                      correoElectronico: currentEmail,
                      nombreUsuario: currentUser,
                      descripcionEvento: description,
                      fechaEvento: new Date(),
                    }
                  };
    return this.http.post(environment.eventoURL, body);
  }

}
