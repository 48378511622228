import { Injectable, Injector } from "@angular/core";
import {
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { EventoService } from "src/app/services/evento/evento.service";

@Injectable()
export class BitacoraInterceptor implements HttpInterceptor {
  constructor(private injector: Injector,
              private eventoService: EventoService
              ) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const reqBody = req.body;

    if (!(reqBody.hasOwnProperty("action") && (reqBody["action"] == "save" || reqBody["action"] == "delete"))) {
      return next.handle(req);
    }

    if (req.url.includes("SstEventoFunc") || req.url.includes("SstRolModuloFunc")) { 
      return next.handle(req);
    }

    return next.handle(req).pipe(
        tap(
            (event : HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                  //Manejar la respuesta
                    this.eventoService.saveEvento().subscribe();
                  }
            },
            (error : HttpErrorResponse ) => {
                if (error.status == 401) {
                  console.log('Carece de credenciales válidas de autenticación para el recurso solicitado');
                }
            }
        )
    )
}
}
