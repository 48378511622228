import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PopoverService } from '../../../components/popover/popover.service';
import { ToolbarUserDropdownComponent } from './toolbar-user-dropdown/toolbar-user-dropdown.component';
import icPerson from '@iconify/icons-ic/twotone-person';
import icGroup from '@iconify/icons-ic/twotone-group';
import icGroups from '@iconify/icons-ic/twotone-groups';
import icAddBusiness from '@iconify/icons-ic/twotone-add-business';
import icEventNote from '@iconify/icons-ic/twotone-event-note';
import icDashboard from '@iconify/icons-ic/twotone-dashboard';
import faScroll from '@iconify/icons-fa-solid/scroll';
import icHomeWork from '@iconify/icons-ic/twotone-home-work';

import { HttpClient,HttpHeaders} from '@angular/common/http';
import { NavigationService } from 'src/@vex/services/navigation.service';
import { respermiso } from 'src/app/interfaces/respermiso.model';
import {AuthService} from '../../../../app/auth.service';

@Component({
  selector: 'vex-toolbar-user',
  templateUrl: './toolbar-user.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserComponent implements OnInit {

  dropdownOpen: boolean;
  icPerson = icPerson;
  icDashboard = icDashboard; 
  currentUser=""; 
  constructor(private popover: PopoverService,  
            private http: HttpClient,
            private authService: AuthService,
            private navigationService: NavigationService,
            private cd: ChangeDetectorRef) { }

  ngOnInit() {
    var a=this.authService.getAuthenticatedUser();
    // console.info(a);
    var that=this;
    // debugger;
    this.authService.getAuthenticatedUser().getSession((err,session)=>{
          if(err){
            return;
          }else{
            // debugger;
            // console.info("groups:"+session.getIdToken().payload['cognito:groups']);
            localStorage.setItem('isMaster', session.getIdToken().payload['cognito:groups']);
            localStorage.setItem('lastAuthUser', session.getIdToken().payload['name']);
            localStorage.setItem('lastEmailUser', session.getIdToken().payload['email']);
            localStorage.setItem('description', '');
            that.currentUser=session.getIdToken().decodePayload().name;

            //Load Permisos:
            this.navigationService.items.splice(0);
            this.navigationService.items.unshift({
              type: 'dropdown',
              label: 'Normativa',
              icon: faScroll,
              children: [
                {
                  type: 'link',
                  label: 'SST - Seguridad y Salud',
                  route: '/normativa/1',
                },
                {
                  type: 'link',
                  label: 'MA - Medio Ambiente',
                  route: '/normativa/2',
                },
                {
                  type: 'link',
                  label: 'CT - Calidad Total',
                  route: '/normativa/3',
                },
                {
                  type: 'link',
                  label: 'HS - Preven. Hostigamiento Sexual',
                  route: '/normativa/4',
                },           
              ]                 
      });
      this.navigationService.items.push({
        type: 'subheading',
        label: 'Administración',
        children: [
            {
              type: 'dropdown',
              label: 'Estructuras Organizativas',
              icon: icHomeWork,
              children: [
                {
                  type: 'link',
                  label: 'Suscripción Pagada',
                  route: '/admin/estorg/contract',
      
                },
                {
                  type: 'link',
                  label: 'Suscripción Gratuita',
                  route: '/admin/eo/without-contract',
                }
              ] 
            },
            {
              type: 'dropdown',
              label: 'Tiles',
              icon: icDashboard,
              children: [
                {
                  type: 'link',
                  label: 'SST - Seguridad y Salud',
                  route: '/modulo-tree/1',
                },
                {
                  type: 'link',
                  label: 'MA - Medio Ambiente',
                  route: '/modulo-tree/2',
                },
                {
                  type: 'link',
                  label: 'CT - Calidad Total',
                  route: '/modulo-tree/3',
                },
                {
                  type: 'link',
                  label: 'HS - Preven. Hostigamiento Sexual',
                  route: '/modulo-tree/4',
                },
              ]
            },
            {
              type: 'link',
              label: 'Matriz de Roles',
              route: '/admin/roles',
              icon: icGroups   
            },
            {
              type: 'dropdown',
              label: 'Usuarios Admin',
              icon: icGroup,
              children: [
                {
                  type: 'link',
                  label: 'Suscripción Pagada',
                  route: '/admin/users/contract',
      
                },
                {
                  type: 'link',
                  label: 'Suscripción Gratuita',
                  route: '/admin/list/without-contract',
                }
              ]
            },
            {
              type: 'link',
              label: 'Asignar Razón Social ',
              route: '/admin/usersocs',
              icon: icAddBusiness   
            },
            {
              type: 'link',
              label: 'Bitácora',
              route: '/admin/bitacora',
              icon: icEventNote   
            },
        ]
      });
            
          }
    });

  }

  showPopover(originRef: HTMLElement) {
    this.dropdownOpen = true;
    this.cd.markForCheck();

    const popoverRef = this.popover.open({
      content: ToolbarUserDropdownComponent,
      origin: originRef,
      offsetY: 12,
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ]
    });

    popoverRef.afterClosed$.subscribe(() => {
      this.dropdownOpen = false;
      this.cd.markForCheck();
    });
  }
}
