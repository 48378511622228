const ORM_APIURL = 'https://j2il0996wk.execute-api.us-west-2.amazonaws.com/icompliance/'; //icompliance
const FUNC_APIURL = 'https://xklpkhmss1.execute-api.us-west-2.amazonaws.com/icompliance/'; //icompliance

export const environment = {
  production: false,
  grupoEconomico    : ORM_APIURL + 'SstGrupoEconomicoFunc',
  sociedad          : ORM_APIURL + 'SstSociedadFunc',
  grupoCentro       : ORM_APIURL + 'SstGrupoCentrosFunc',
  centro            : ORM_APIURL + 'SstCentroFunc',
  rolURL            : ORM_APIURL + 'SstRolFunc',
  tipoIdentificaURL : ORM_APIURL + 'SstTipoIdentificacionFunc',
  moduloURL         : ORM_APIURL + 'SstModuloFunc',
  userURL           : ORM_APIURL + 'SstUsuarioFunc',
  userEntitieRolURL : ORM_APIURL + 'SstUsuarioSociedadRolFunc',
  rolModuloURL      : ORM_APIURL + 'SstRolModuloFunc',
  formatoModuloURL  : ORM_APIURL + 'SstFormatoModuloFunc',
  versionURL        : ORM_APIURL + 'SstVersionFunc',
  normativaURL      : ORM_APIURL + 'SstNormativaFunc',
  userEstOrgURL     : ORM_APIURL + 'SstUsuarioEstOrgFunc',
  eventoURL         : ORM_APIURL + 'SstEventoFunc',
  moduloTreeURL   : FUNC_APIURL + 'sst_ModulosTree',     // actualizado
  entitieAllURL   : FUNC_APIURL + 'sst_GenGrupoEcoAllOrdered', // actualizado
  entitieIdURL    : FUNC_APIURL + 'sst_GenGrupoEcoByIdOrdered',
  modulosByRolURL : FUNC_APIURL + 'sst_ModulosByRol',
  adminForEntitie : FUNC_APIURL + 'sst_AdminForEntitie',
  getUsers        : FUNC_APIURL + 'sst_getUsers',        // actualizado
  bizCreateRol    : FUNC_APIURL + 'sst_BizCreateRol',
  activateUser    : FUNC_APIURL + 'sst_BizActivateUser',
  deleteFormat    : FUNC_APIURL + 'sst_BizDeleteFormat',
  createModule    : FUNC_APIURL + 'sst_BizCreateModule',
  societyDocs     : FUNC_APIURL + 'sst_SocietyDocs',
  S3_URL          : 'https://twqm0b7nh4.execute-api.us-west-2.amazonaws.com/icompliance/',
};

export const AWSInfo = {                    // master qas
  s3Bucket: 'qas.icompliance.files',
  groupName: 'icompliance-qas-master',
  urlCompliance: 'qas.icompliance.files/compliance-logo/compliance-logo-141.png'
};

// master
export const cognitoConfig = {              // master qas
  userPool: {
      UserPoolId: 'us-west-2_APakK7BB9',
      ClientId: '3so1dkqprqvfn3i61fed7j6sv6',
      region: 'us-west-2'
  },
  identityPoolId: 'us-east-2:606824fd-7f5e-4578-bcd8-f403dae9ba72'
};

// portal
export const cognitoConfigUsers = {          // qas
  userPool: {
      UserPoolId: 'us-west-2_ZcTLBisSD',
      ClientId: '3q8b18jmdqm57jnks9le2tvtoa',
      region: 'us-west-2'
  },
  identityPoolId: 'us-west-2:66ab9ea3-caed-4a32-94b7-3f5e06a20c3b'
};

export const gestion = {
  frontSST  : 1,
  default   : 1
};

export const list = {
  moduloTypes : ['subheading','dropdown','link'],
  eoLabels    : [
    {
      cod: 1,
      name: 'Establecimiento',
      color: 'orange'
    },
    {
      cod: 10,
      name: 'Grupo de Establecimientos',
      color: 'purple'
    },
    {
      cod: 100,
      name: 'Sociedad',
      color: 'cyan'
    },
    {
      cod: 111,
      name: 'Soc-GE-Es',
      color: 'gray'
    }
  ]
};